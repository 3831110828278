import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box } from '@material-ui/core';
export default function QRCodeDialog({ open, onClose, image, headerText, subText, fileName }) {
    const canvasRef = useRef(null);

    const base64String = useMemo(() => {
        if (image && image.length > 0) {
            return `data:image/png;base64,${image}`;
        }
    })

    const drawCanvas = useCallback(async () => {    
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        try {
            const qrImage = await new Promise((resolve, reject) => {
                const img = new Image();
                img.src = base64String;
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
            });
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(qrImage, 0, 0, canvas.width, canvas.height - 60);

            ctx.font = 'bold 24px Arial';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'black';
            ctx.fillText(headerText, canvas.width / 2, canvas.height - 40);
            ctx.font = '16px Arial';
            ctx.fillText(subText, canvas.width / 2, canvas.height - 20);
        } catch (error) {
            console.error('Failed to load image.', error);
        }
    });

    useEffect(() => {      
        if (open && base64String && canvasRef.current) {          
            drawCanvas();
        }
    }, [open, drawCanvas, base64String]);


    const handleDownload = async () => {    
        await drawCanvas();
        const canvas = canvasRef.current;
        if (!canvas) return;
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = fileName;
        link.click();
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <canvas ref={canvasRef} width={400} height={500} hidden />
                    <img
                        src={base64String}
                        alt="QR Code"
                        style={{ width: 300, height: 300, marginBottom: 16 }}
                    />
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}>
                        {headerText}
                    </Typography>
                    <Typography sx={{ textAlign: 'center' }}>
                        {subText}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>                     
                    <Button onClick={handleDownload} color="primary" title="download">
                        <GetAppIcon />
                    </Button>               
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}